const tw = {
  header: {
    //
    totalNumberOfOperations: "総カット数",
    anesthesia: "上麻",
    localAnesthesia: "局所麻酔",
    operatingRoom: "手術室",
    OpCountApiError: "獲取手術室信息列表失敗，請檢查網絡."
  },
  detailFooter: {
    anaesNote: "麻酔メモ",
    medicalDevice: "医療機器",
    medicalMaterials: "エーザイ",
    schedule: "手術のスケジュール設定"
  },
  footer: {
    dayShift: "日勤",
    night: "夜勤",
    bigNight: "大規模な夜勤",
    maneuverable: "モバイルクラス",
    filter: "条件付き検索",
    alreadyFirstPage: "もう最初のページです",
    alreadyLastPage: "これはもう最後のページです"
  },
  duty: {
    flow: "モバイルナース",
    brushHands: "ハンドブラッシング看護師",
    specializedSurgery: "外科専門医",
    dayShiftApiError: "日勤情報の取得に失敗しました、ネットワークエラーです"
  },
  notice: {
    auditNoPassed: "レビューは承認されていません",
    important: "重要",
    noticeApiError: "手術室情報リストの取得に失敗しました。ネットワークを確認してください"
  },
  room: {
    departmentName: "部門",
    operationType: "ナイフの種類",
    operationRoomID: "手術室番号",
    scrubNurse: "ハンドブラッシング看護師",
    flow: "モバイルナース",
    determine: "確認する",
    surgeon: "外科医長",
    surgical: "技術",
    anaesDoctor: "麻酔科医",
    regularKnife: "定期的な手術",
    emergencyKnife: "緊急ナイフ",
    sharpKnife: "第二レベルの緊急事態",
    gastroenterology: "消化器科",
    generalSurgery: "一般外科",
    neuroSurgery: "脳神経外科",
    urology: "泌尿器科",
    orthopedics: "整形外科",
    plasticSurgery: "形成外科",
    thoracicSurgery: "胸部外科",
    pediatricSurgery: "小児外科",
    cardiovascularSurgery: "心臓血管外科",
    obstetricsAndGynecology: "産婦人科医",
    dentistry: "歯科",
    oralAndMaxillofacialSurgery: "口腔顎顔面外科",
    ophthalmology: "眼科",
    otolaryngology: "耳鼻科",
    anesthesiaDepartment: "麻酔科",
    loading: "データのロード",
    roomFindByParamAPIError: "手術室情報リストのフィルタリングに失敗しました。ネットワーク接続を確認してください",
    getRoomFindByParamAPIError: "手術室情報リストの取得に失敗しました。ネットワーク接続を確認してください",
    toBeArranged: "保留中の手配",
    waitingForPatients: "患者を待っています",
    EnterOperatingRoom: "手術室に入る",
    AnesthesiaPrelude: "麻酔前",
    Scored: "切開が行われました",
    BeforeClosingWound: "創傷閉鎖前",
    AfterClosingWound: "創傷閉鎖後",
    LeaveOperatingRoom: "手術室を出る",
    hasNotStarted: "未開始",
    waitingRoom: "待合室",
    enterOR: "入OR",
    signIn: "Sign in",
    timeOut: "Time out",
    signOut: "Sign out",
    cancel: "キャンセル",
    finish: "完了",
    regularKnifeRow: "ナイフ列（レギュラー）",
    generalEmergencyKnife: "一般救急外科",
    insertKnife: "ナイフを入れる",
    superEmergencySurgery: "超緊急手術",
    urgentKnife: "緊急ナイフ",
    beautyKnife: "美容整形",
    delete: "消去"
  },
  patient: {
    operationName: "手術",
    departmentName: "部門",
    operationType: "ナイフの種類",
    operationRoomID: "手術室番号",
    doctor: "外科医長",
    primaryNurse: "スペシャリスト",
    operationStart: "手術開始予定時間",
    patientStatus: "患者の状態",
    determine: "確認する",
    regularKnife: "定期的な手術",
    emergencyKnife: "緊急ナイフ",
    sharpKnife: "第二レベルの緊急事態",
    gastroenterology: "消化器科",
    generalSurgery: "一般外科",
    neuroSurgery: "脳神経外科",
    urology: "泌尿器科",
    orthopedics: "整形外科",
    plasticSurgery: "形成外科",
    thoracicSurgery: "胸部外科",
    pediatricSurgery: "小児外科",
    cardiovascularSurgery: "心臓血管外科",
    obstetricsAndGynecology: "産婦人科医",
    dentistry: "歯科",
    oralAndMaxillofacialSurgery: "口腔顎顔面外科",
    ophthalmology: "眼科",
    otolaryngology: "耳鼻科",
    anesthesiaDepartment: "麻酔科",
    operationInfoApiError: "手術室情報リストの取得に失敗しました。ネットワークを確認してください",
    liquid: "流体",
    touch: "接触",
    call: "呼吸",
    foam: "水滴",
    infect: "感染",
    other: "その他",
    ownExpense: "自己負担",
    VIP: "VIP",
    drugSensitivity: "薬物アレルギー",
    regularKnifeRow: "ナイフ列（レギュラー）",
    generalEmergencyKnife: "一般救急外科",
    insertKnife: "ナイフを入れる",
    superEmergencySurgery: "超緊急手術",
    urgentKnife: "緊急ナイフ",
    beautyKnife: "美容整形",
    delete: "消去"
  },
  detail: {
    birthdate: "出生日期",
    doctor: "外科医長",
    operationName: "手術名",
    specialNote: "照護註記",
    itemsOwnExpense: "自己負担品",
    thinking: "検討中",
    medicalHistory: "病歴",
    healthInsuranceAnesthesiaDepth: "国民健康保険の麻酔深度",
    difficultIntubation: "挿管が難しい",
    identity: "識別タイプ",
    surgicalInstruments: "手術器具",
    quantity: "量",
    no: "番号",
    page: "ページ",
    total: "合計",
    patientApiError: "患者情報の取得に失敗しました。ネットワーク接続を確認してください。",
    liquid: "流体",
    touch: "接触",
    call: "呼吸",
    foam: "水滴",
    infect: "感染",
    other: "その他",
    ownExpense: "自己負担",
    VIP: "VIP",
    drugSensitivity: "薬物アレルギー",
    swordSequence: "手術の指示",
    patient: "患者",
    department: "部門",
    technique: "技術",
    chiefSurgeon: "外科医長",
    OpSortError: "手術オーダー情報の取得に失敗しました。ネットワーク接続を確認してください",
    toBeArranged: "保留中の手配",
    waitingForPatients: "患者を待っています",
    EnterOperatingRoom: "手術室に入る",
    AnesthesiaPrelude: "麻酔前",
    Scored: "切開が行われました",
    BeforeClosingWound: "創傷閉鎖前",
    AfterClosingWound: "創傷閉鎖後",
    LeaveOperatingRoom: "手術室を出る",
    hasNotStarted: "未開始",
    waitingRoom: "待合室",
    enterOR: "入OR",
    signIn: "Sign in",
    timeOut: "Time out",
    signOut: "Sign out",
    cancel: "キャンセル",
    finish: "完了"
  }
};

export default tw;
