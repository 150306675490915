const tw = {
  header: {
    //
    totalNumberOfOperations: "Total number of cuts",
    anesthesia: "general anesthesia",
    localAnesthesia: "Local anesthesia",
    operatingRoom: "operating room",
    OpCountApiError: "The retrieval of the operating room information list failed. Please check the network connection."
  },
  detailFooter: {
    anaesNote: "Anesthesia Notes",
    medicalDevice: "Medical Equipment",
    medicalMaterials: "Medical Supplies",
    schedule: "Surgery Scheduling"
  },
  footer: {
    dayShift: "Day Shift",
    night: "Evening Shift",
    bigNight: "Night Shift",
    maneuverable: "Flexible Shift",
    filter: "Conditional Search",
    alreadyFirstPage: "This is already the first page.",
    alreadyLastPage: "This is already the last page."
  },
  duty: {
    flow: "mobile nurse",
    brushHands: "Hand brushing nurse",
    specializedSurgery: "Surgery Specialist",
    dayShiftApiError: "Failed to retrieve day shift information due to a network error."
  },
  notice: {
    auditNoPassed: "Review Not Approved",
    important: "Important",
    noticeApiError: "Failed to retrieve the operating room information list. Please check the network."
  },
  room: {
    departmentName: "Department",
    operationType: "Type of surgery",
    operationRoomID: "Operating room number",
    scrubNurse: "Hand brushing nurse",
    flow: "mobile nurse",
    determine: "Confirm",
    surgeon: "Chief surgeon",
    surgical: "technique",
    anaesDoctor: "anesthesiologist",
    regularKnife: "Routine Surgery",
    emergencyKnife: "emergency knife",
    sharpKnife: "Secondary emergency",
    gastroenterology: "Gastroenterology",
    generalSurgery: "general surgey",
    neuroSurgery: "neurosurgery",
    urology: "Urology",
    orthopedics: "Department of Orthopedics",
    plasticSurgery: "plastic surgery",
    thoracicSurgery: "Thoracic Surgery",
    pediatricSurgery: "Pediatric Surgery",
    cardiovascularSurgery: "Cardiovascular Surgery",
    obstetricsAndGynecology: "Obstetrics and Gynecology",
    dentistry: "Dentistry",
    oralAndMaxillofacialSurgery: "Oral and maxillofacial surgery",
    ophthalmology: "ophthalmology",
    otolaryngology: "Otolaryngology",
    anesthesiaDepartment: "Anesthesia Department",
    loading: "Loading Data",
    roomFindByParamAPIError: "Failed to filter the operating room information list. Please check the network connection.",
    getRoomFindByParamAPIError: "Failed to retrieve the operating room information list. Please check the network connection.",
    toBeArranged: "Pending Arrangement",
    waitingForPatients: "Waiting for Patient",
    EnterOperatingRoom: "Enter Operating Room",
    AnesthesiaPrelude: "Pre-Anesthesia",
    Scored: "Incision Made",
    BeforeClosingWound: "Before Wound Closure",
    AfterClosingWound: "After Wound Closure",
    LeaveOperatingRoom: "Exit Operating Room",
    hasNotStarted: "Not Started",
    waitingRoom: "Waiting Room",
    enterOR: "入OR",
    signIn: "Sign in",
    timeOut: "Time out",
    signOut: "Sign out",
    cancel: "Cancel",
    finish: "Complete",
    regularKnifeRow: "Surgical knife row (conventional)",
    generalEmergencyKnife: "General Emergency Surgery",
    insertKnife: "unscheduled surgery",
    superEmergencySurgery: "super emergency surgery",
    urgentKnife: "urgent  knife",
    beautyKnife: "Cosmetic Surgery",
    delete: "Delete"
  },
  patient: {
    operationName: "Surgery",
    departmentName: "Department",
    operationType: "Type of Knife",
    operationRoomID: "Operating room number",
    doctor: "Chief surgeon",
    primaryNurse: "Specialist",
    operationStart: "Estimated Surgery Start Time",
    patientStatus: "Patient Status",
    determine: "Confirm",
    regularKnife: "Routine Surgery",
    emergencyKnife: "emergency knife",
    sharpKnife: "Secondary emergency",
    gastroenterology: "Gastroenterology",
    generalSurgery: "general surgey",
    neuroSurgery: "neurosurgery",
    urology: "Urology",
    orthopedics: "Department of Orthopedics",
    plasticSurgery: "plastic surgery",
    thoracicSurgery: "Thoracic Surgery",
    pediatricSurgery: "Pediatric Surgery",
    cardiovascularSurgery: "Cardiovascular Surgery",
    obstetricsAndGynecology: "Obstetrics and Gynecology",
    dentistry: "Dentistry",
    oralAndMaxillofacialSurgery: "Oral and maxillofacial surgery",
    ophthalmology: "ophthalmology",
    otolaryngology: "Otolaryngology",
    anesthesiaDepartment: "Anesthesia Department",
    operationInfoApiError: "The retrieval of the operating room information list failed. Please check the network connection.",
    liquid: "Fluids",
    touch: "Contact",
    call: "Breathing",
    foam: "Droplets",
    infect: "Infection",
    other: "Others",
    ownExpense: "Out-of-Pocket",
    VIP: "VIP",
    drugSensitivity: "Drug Allergy",
    regularKnifeRow: "Surgical knife row (conventional)",
    generalEmergencyKnife: "General Emergency Surgery",
    insertKnife: "unscheduled surgery",
    superEmergencySurgery: "super emergency surgery",
    urgentKnife: "urgent  knife",
    beautyKnife: "Cosmetic Surgery",
    delete: "Delete"
  },
  detail: {
    birthdate: "Date of Birth",
    doctor: "Chief surgeon",
    operationName: "Surgery Name",
    specialNote: "Care Notes",
    itemsOwnExpense: "Out-of-Pocket Items",
    thinking: "Under Consideration",
    medicalHistory: "Medical History",
    healthInsuranceAnesthesiaDepth: "NHI Anesthesia Depth",
    difficultIntubation: "Difficult Intubation",
    identity: "Identification Type",
    surgicalInstruments: "Surgical Instruments",
    quantity: "Quantity",
    no: "Number",
    page: "Page",
    total: "Total",
    patientApiError: "Failed to retrieve patient information. Please check the network connection.",
    liquid: "Fluids",
    touch: "Contact",
    call: "Breathing",
    foam: "Droplets",
    infect: "Infection",
    other: "Others",
    ownExpense: "Out-of-Pocket",
    VIP: "VIP",
    drugSensitivity: "Drug Allergy",
    swordSequence: "Surgery Order",
    patient: "Patient",
    department: "Department",
    technique: "technique",
    chiefSurgeon: "Chief surgeon",
    OpSortError: "Failed to retrieve surgery order information. Please check the network connection.",
    toBeArranged: "Pending Arrangement",
    waitingForPatients: "Waiting for Patient",
    EnterOperatingRoom: "Enter Operating Room",
    AnesthesiaPrelude: "Pre-Anesthesia",
    Scored: "Incision Made",
    BeforeClosingWound: "Before Wound Closure",
    AfterClosingWound: "After Wound Closure",
    LeaveOperatingRoom: "Exit Operating Room",
    hasNotStarted: "Not Started",
    waitingRoom: "Waiting Room",
    enterOR: "入OR",
    signIn: "Sign in",
    timeOut: "Time out",
    signOut: "Sign out",
    cancel: "Cancel",
    finish: "Complete"
  }
};

export default tw;
